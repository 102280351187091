import styled from "styled-components";
export const LinkAbsolute = styled.a`
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 3;
`;

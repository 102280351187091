import ContentIcon from "@/shared/ContentIcon";
import { IcoPaywall, PaywallIcoBox } from "./styles";

const ContentPayWallExclusive = (props) => {
  const {
    nameIcon,
    fill,
    sectionClass = "" ,
    style
  } = props;



  return (
    // <PaywallIcoBox className={sectionClass} >
    <PaywallIcoBox className={`${sectionClass} icon-paywall-box`} style={style}>
        <IcoPaywall><ContentIcon width="8px" height="8px" nameIcon='star'/></IcoPaywall>
        <p>Solo suscriptores</p>
    </PaywallIcoBox>
   
  )
}

export default ContentPayWallExclusive;


const clarinVarSub2Bold = `
font-family: "ClarinvarVF";
font-variation-settings: "wght" 700,"opsz" 66 ;
`
const clarinVarSub2Regular = `
font-family: "ClarinvarVF";
font-variation-settings: "wght" 400,"opsz" 66 ;
`
const clarinVarSub2ExtraBold = `
font-family: "ClarinvarVF";
font-variation-settings: "wght" 800,"opsz" 66 ;
`

const sansBold = `
font-family: "AdelleSansClarin-VF";
font-variation-settings: "wght" 600 ;
`
const sansRegular = `
font-family: "AdelleSansClarin-VF";
font-variation-settings: "wght" 200 ;
`

const sansSemiBold = `
font-family: "AdelleSansClarin-VF";
font-variation-settings: "wght" 300 ;
`
const sansLight = `
font-family: "AdelleSansClarin-VF";
font-variation-settings: "wght" 0 ;
`

export const Font = {
  clarinVarSub2Bold,
  clarinVarSub2Regular,
  clarinVarSub2ExtraBold,
  sansBold,
  sansRegular,
  sansLight,
  sansSemiBold,
};


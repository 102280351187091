import styled from "styled-components";
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";

export const Container = styled.div`
  position: relative;
  margin-bottom: 12px;
  &.sepFullAdaptive {
    width: 100%;
  }
  &.apertura-content {
    display: flex;
    margin: 20px auto 20px;
    justify-content: center;
    hr {
      display: none;
    }
  }

  ${breakpoints.phone} {
    width: 100%;
    margin: 10px auto;
  }
  hr {
    margin: 0;
    border: 0 none;
    &:before {
      content: "";
      height: 1px;
      width: 100%;
      background: #e0e0e0;
      display: block;
      margin: 3px 0;
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          background: ${ColorDark.greySeparator};
        }
      }
    }
    &:after {
      content: "";
      height: 1px;
      width: 100%;
      background: #e0e0e0;
      display: block;
      margin-top: 5px;
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          background: ${ColorDark.greySeparator};
        }
      }
    }
  }
`;
export const Section = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  height: 50px;
  //gap: 12px;
  justify-content: space-between;
  .left {
    display: flex;
  }
  .sponsorHead {
    max-width: 300px;
    max-height: 40px;
    overflow: hidden;
    img {
      width: 100%;
      height: 40px;
    }
  }
  ${breakpoints.phone} {
    width: 100%;
    flex-wrap: wrap;
    padding: 10px 0;
    height: auto;
  }
  ${breakpoints.darkMode} {
    [data-theme="dark"] & {
      border-bottom: 1px solid ${ColorDark.greySeparator};
    }
  }
  p {
    display: none;
  }
  .sep {
    font-size: 18px;
    ${Font.sansBold};
    color: ${Color.black};
    margin-top: 3px;
    display: block;
    margin-right: 10px;

    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        color: ${ColorDark.textLight};
      }
    }
  }
  &.apertura-content {
    border-bottom: 1px solid ${Color.mainColor};
    justify-content: center;
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        border-bottom: 1px solid ${ColorDark.mainColor};
      }
    }
    .sep {
      color: ${Color.mainColor};
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          color: ${ColorDark.mainColor};
        }
      }
    }
  }
`;

export const ExtraLink = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-left: 25px;
  ${breakpoints.phone} {
    margin-left: 0;
    row-gap: 10px;
  }
  li {
    padding: 5px 15px;
    border: 1px solid #000;
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        border: 1px solid ${ColorDark.textLight};
      }
    }
    :first-child {
      position: relative;
      &:before {
        content: "";
        width: 1px;
        height: 30px;
        background: #e0e0e0;
        display: block;
        position: absolute;
        left: -21px;
        ${breakpoints.phone} {
          display: none;
        }
      }
    }
    a {
      font-size: 14px;
      ${Font.sansRegular};
      color: ${Color.black};
      transition-duration: 0.3s;
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          color: ${ColorDark.textLight};
        }
      }
      &:hover {
        color: #666;
      }
    }
  }
`;
